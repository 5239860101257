<template>
	<div>


		<v-container class="pa-0" fluid>
			<v-row align="center" justify="center">
				<v-col sm="12" md="10" lg="8" xl="8" class="pt-0">
					<v-carousel
							height="auto"
					>
						<v-carousel-item
								v-for="(image,i) in shop.shop_images"
								:key="i"
						>

							<v-img
									contain
									:src="image.src"
									width="100%"
                  eager
							/>

						</v-carousel-item>
					</v-carousel>
				</v-col>
			</v-row>

			<v-card
					outlined
					tile
					class="border-none shop-card">
				<v-row align="center" justify="center">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-card-subtitle class="font-weight-bold">
							<v-chip
									class="mr-2 mb-2"
									color="black"
									outlined
									label
									small
									disabled

							>
								エリア
							</v-chip>
							{{shop.shop_area}}
							<br>
							<v-chip
									class="mr-2 mb-2"
									color="black"
									outlined
									label
									small
									disabled
							>
								ジャンル
							</v-chip>
							{{shop.shop_genre}}
						</v-card-subtitle>
						<div class="shop-title">
							<v-card-title class="font-weight-bold">
								{{shop.name}}
							</v-card-title>

							<v-card-subtitle>
								{{shop.furigana}}
							</v-card-subtitle>

							<v-card-subtitle>
								〒{{shop.zip}} {{shop.city}}{{shop.address1}}{{shop.address2}}<br>
								{{shop.shop_address_note}}
							</v-card-subtitle>
							<v-btn
									tile
									depressed
									color="white"
									class="icon-map"
							>
								<a :href="shop.shop_googlemap" target="_blank"><i class="fas fa-map-marker-alt mr-1"></i>Google Map</a>
							</v-btn>
						</div>
						<v-card-subtitle>
							<div v-if="shop.shop_lunch_price">
								<span style="margin-right:1em;">ランチ</span>
								<span
										class="ml-2 title">&yen;{{shop.shop_lunch_price}}〜</span>(税サ別 / 人)<br>
							</div>
							<div v-if="shop.shop_dinner_price">
								ディナー
								<span class="ml-2 title">&yen;{{shop.shop_dinner_price}}〜</span>(税サ別 / 人)
							</div>
						</v-card-subtitle>
						<v-divider></v-divider>
						<v-card-text class="pb-0 newLine">
							{{shop.shop_explanation}}
						</v-card-text>
					</v-col>
				</v-row>

        <!-- _______________________________________________ コース料理 -->
				<v-row align="center" justify="center" v-for="course in products" :key="course.no">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-row class="grey lighten-3">
							<v-col sm="6" md="6" lg="5" xl="5" class="pa-0">
								<v-img
										contain
										:src="course.imgMain"
										width="100%"
								/>
							</v-col>
							<v-col sm="6" md="6" lg="7" xl="7" color="black">
								<v-card-subtitle class="font-weight-bold">
									{{course.itemName}} <br>
									¥{{course.price | toThousandFilter}}(税サ別 / 人)
								</v-card-subtitle>
								<v-card-text>
									{{course.comment}}
								</v-card-text>
								<v-card-text>
									<v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
										<v-spacer></v-spacer>
										<v-btn
												color="#e60044"
												dark
												depressed
												tile
												@click="openPlanDialog(course)"
										>
											コース内容
										</v-btn>
									</v-col>

								</v-card-text>

							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<!-- _______________________________________________ コース料理dialog -->
				<v-dialog
						v-model="planDialog"
						max-width="800"
				>
					<v-card
							color="grey lighten-3">
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
									black
									outlined
									depressed
									tile
									@click="closePlanDialog()"
							>
								<i class="fas fa-times"></i>
							</v-btn>
						</v-card-actions>
						<v-card-title>
							<v-carousel
									height="auto"
							>
								<v-carousel-item
										v-for="(image,i) in planDialogItem.images"
										:key="i"
								>

									<v-img
											contain
											:src="image.src"
											width="100%"
									/>

								</v-carousel-item>
							</v-carousel>
						</v-card-title>
						<v-card-title class="font-weight-bold">
							{{planDialogItem.itemName}}<br>
							&yen;{{planDialogItem.price | toThousandFilter}}(税サ別 / 人)
						</v-card-title>
						<v-card-text class="red--text">
							ご予約成立時はお一人様あたり390円のみご決済となります。<br/>
							上記コース料金、ドリンク、サービス料、消費税は店舗にてお支払いください
						</v-card-text>
						<v-card-text>
							{{planDialogItem.comment}}
						</v-card-text>
						<v-spacer></v-spacer>
						<v-card-subtitle v-html="planDialogItem.detailsComment"/>

            <v-card-text>
              <v-btn
                  color="#e60044"
                  dark
                  depressed
                  tile
                  block
                  @click="toReservation(planDialogItem.no)"
              >
                今すぐお店に行く
              </v-btn>
            </v-card-text>



						<v-row align="center" justify="center">
							<v-col sm="12" md="12" lg="12" xl="12" align="center" justify="center">
								<v-spacer></v-spacer>
							</v-col>
						</v-row>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
									black
									outlined
									depressed
									tile
									@click="closePlanDialog()"
							>
								<i class="fas fa-times"></i>
							</v-btn>
						</v-card-actions>

					</v-card>
				</v-dialog>



				<v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row align="center" justify="center">
              <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
                <v-select
                    v-model="course"
                    :items="products"
                    item-text="itemName"
                    item-value="no"
                    :rules="coursesRules"
                    required
                    filled
                    background-color="#e3e3e4"
                    label="コースを選ぶ"

                    item-color="black"
                ></v-select>

              </v-col>

            </v-row>
            <v-row align="center" justify="center" class="btn-reserve">
              <v-col sm="12" md="10" lg="8" xl="8">
                <v-btn
                    color="#e60044"
                    dark
                    depressed
                    tile
                    block
                    @click="toReservation()"
                >
                  今すぐお店に行く
                </v-btn>

              </v-col>
            </v-row>
          </v-form>

					<!--<v-row align="center" justify="center" class="btn-reserve">
						<v-col sm="12" md="10" lg="8" xl="8">
							<v-btn
									color="#e60044"
									dark
									depressed
									tile
									@click.stop="planDialog02 = true"
							>
								予約カレンダー
							</v-btn>
							<v-dialog
								v-model="planDialog02"
								max-width="500"
						>
							<v-card
							color="grey lighten-3">
							<v-row align="center" justify="center">
							<v-col sm="12" md="12" lg="12" xl="12" align="center" justify="center">
									<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
									black
									outlined
									depressed
									tile
									@click="planDialog02 = false"
									>
										<i class="fas fa-times"></i>
									</v-btn>
								</v-card-actions>
							</v-col>
							<v-col sm="12" md="12" lg="12" xl="12" align="center" justify="center">

								<v-date-picker
										v-model="picker"
										locale="ja"
										min="2020-08-13"
								>

								</v-date-picker>
							</v-col>
							<v-spacer></v-spacer>
							&lt;!&ndash;<v-col sm="12" md="12" lg="12" xl="12" align="center" justify="center">

								<v-time-picker v-model="picker2"></v-time-picker>
							</v-col>&ndash;&gt;

								<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
									<v-select
											:items="reserveTime"
											label="ご来店時間"
									></v-select>
								</v-col>

								<v-col sm="12" md="10" lg="8" xl="8">
									<v-btn
											color="#e60044"
											dark
											depressed
											tile
											block
											to="/reservation"
									>
										予約する
									</v-btn>

								</v-col>
							<v-col sm="12" md="12" lg="12" xl="12" align="center" justify="center">

								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
									black
									outlined
									depressed
									tile
									@click="planDialog02 = false"
									>
										<i class="fas fa-times"></i>
									</v-btn>
								</v-card-actions>
							</v-col>

						</v-row>
							</v-card>
						</v-dialog>
						</v-col>
					</v-row>-->

					<!--					<v-row align="center" justify="center" class="btn-reserve">
											<v-col sm="6" md="5" lg="4" xl="4">
												<v-btn
														color="#e60044"
														dark
														depressed
														tile
														block
														to="./detail2"
												>
													通販
												</v-btn>
											</v-col>
											<v-col sm="6" md="5" lg="4" xl="4">
												<v-btn
														color="#e60044"
														dark
														depressed
														tile
												>
													テイクアウト予約
												</v-btn>
											</v-col>
										</v-row>-->


					<v-row align="center" justify="center" class="btn-reserve">
						<v-col sm="12" md="10" lg="8" xl="8" class="text-center">
							ご予約には会員登録が必要となります。
						</v-col>
					</v-row>

					<v-row align="center" justify="center" class="btn-register">
						<v-col sm="6" md="3" lg="2" xl="2">
							<v-btn
									black
									outlined
									depressed
									tile
									to="/register"
							>
								新規会員登録はこちら
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>

				<v-row align="center" justify="center" class="grey lighten-3">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-card-title>プラン注意事項</v-card-title>
						<v-card-text v-html="fcms.note_plan" />

						<v-card-title>キャンセル料</v-card-title>
						<v-card-text v-html="fcms.note_cancel" />

					</v-col>
				</v-row>
			</v-card>


			<v-card
          v-if="shop.shop_ec == 1"
					color="black"
					dark
					tile
			>
				<v-card-title
						class="text-center justify-center">ご自宅への配送
				</v-card-title>
				<v-row align="center" justify="center" class="white">
				<v-col sm="12" md="10" lg="8" xl="8">
					<v-card-text class="text-dinw">ご家庭へシェフの味をお届け致します。
お店の一流の食材と味をご自宅でお楽しみ頂けます。</v-card-text>

            <v-card-text class="btn-reserve btn-house">
              <v-btn
                  color="#e60044"
                  dark
                  depressed
                  tile
                  block
                  :to="`/product/${shop.no}`"
              >
                <i class="mdi mdi-truck"></i>ご自宅で味わうお店の味
              </v-btn>
            </v-card-text>

				</v-col>
				</v-row>

				<v-row align="center" justify="center" class="grey lighten-3">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-card-title class="text-dinw">ご注意事項</v-card-title>
						<v-card-text class="text-dinw">・ご注文確定後の商品の変更・キャンセルは、原則的にお受けできませんのでご了承ください。</v-card-text>
					</v-col>
				</v-row>
			</v-card>

			<v-card
					color="black"
					dark
					tile
			>
				<v-card-title
						class="text-center justify-center">シェフ
				</v-card-title>
				<v-img
						contain
						:src="shop.shop_chef_image"
						width="100%"
				/>
				<v-img
						class="chef-icon"
						contain
						src="../assets/logo-icon.svg"
						width="20%"
				/>
			</v-card>

			<v-card
					outlined
					tile
					class="border-none shop-card">
				<v-row align="center" justify="center" class="grey lighten-3">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-card-title>
							{{shop.shop_chef_name}}
						</v-card-title>

						<v-card-text>
							{{shop.shop_chef_introduction}}
						</v-card-text>
					</v-col>
				</v-row>
			</v-card>

			<!--			<v-card
								color="black"
								dark
								tile
						>
							<v-card-title
									class="text-center justify-center">シェフがお薦めするよく通う名店
							</v-card-title>
							<div
								v-for="recommendedShop in shop.shop_chef_recommended"
								:key="recommendedShop.no">

							<router-link :to="'/shop/'+recommendedShop.no">
							<v-img
									contain
									:src="recommendedShop.image"
									width="100%"
							/>
							</router-link>
							<v-row align="center" justify="center" >
								<v-col sm="12" md="10" lg="8" xl="8">
									<v-card-title
											class="text-center">{{recommendedShop.name}}
									</v-card-title>
								</v-col>
							</v-row>
							</div>
						</v-card>-->


			<!--<v-card
				outlined
				tile
				class="border-none shop-card">
				<v-card-text>
					<v-row align="center" justify="center" class="mt-12 mb-12">
						<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
							<v-select
									:items="shops"
									filled
									background-color="#e3e3e4"
									label="同じカテゴリから選ぶ"
									class="justify-center select-menu"
									item-color="black"
							></v-select>
						</v-col>

						<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
							<v-select
									:items="shops"
									filled
									background-color="#e3e3e4"
									label="同じエリアから選ぶ"
									class="justify-center select-menu"
									item-color="black"
							></v-select>
						</v-col>

						<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
							<v-select
									:items="shops"
									filled
									background-color="#e3e3e4"
									label="同じ価格帯から選ぶ"
									class="justify-center select-menu"
									item-color="black"
							>
							</v-select>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>-->
		</v-container>
	</div>
</template>

<script>
	import fcms from "@/api/fcms.js";
	import products from "@/api/products.js";
	import shops from "@/api/shops.js";

	export default {
		props: {
			no: Number,
		},
		data() {
			return {
				// fcms
				fcms: [],
        valid: false,


        // 商品情報
        products:[],
        productsQuery:{
          type:1,
          shop:null,
        },

        course:null,     // 選択コース料理
        coursesRules:[
          v => !!v || 'コースを選択してください',
        ],
				shop: [],        // 店舗情報
				drawer: false,

				peoples: ['1人', '2人', '3人', '4人'],
				shops: ['LA BETTOLA da Ochiai', 'ARMONICO', 'AU GAMIN DE TOKIO',
					'Comptoir Missago', 'GENEI.WAGAN', 'Margotto e Baciare', '幸せ三昧', '慈華', '鈴なり'],
				reserveTime: ['11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
				// ご注文プランの選択
				picker: new Date().toISOString().substr(0, 10),
				picker2: null,

				// コース料理ダイアログ
				planDialog:false,
				planDialogItem: [],

				people:0,
				rDateTime:0,

			}
		},
		updated() {
			// dialogのスクロール位置を初期化
			const elements = document.getElementsByClassName('v-dialog--active');
			if (!elements || !elements.length) {
				// 要素が取得できなかった場合は終了
				return;
			}
			elements[0].scrollTop = 0;
		},
		created() {
			// コース情報を取得
      this.productsQuery.shop = this.no
			products.fetchList(this.productsInfo, this.productsQuery);

			// 店舗情報を取得
			shops.fetch(this.shopInfo,this.no)

			// fcms 注意事項
			fcms.fetchList(this.setFcms,5,1);
		},
		methods: {
			/** ____________________________________________________________________ コース料理ダイアログ  */
			// Open
			openPlanDialog(item){
				this.planDialog = true
				this.planDialogItem = item
			},
			// Close
			closePlanDialog(){
				this.planDialog = false
				this.planDialogItems = []
			},
      /** ____________________________________________________________________ セレクトメニュー コースの選択  */
      selectedCourse(value){
        this.course = value
      },
      /** ____________________________________________________________________ 予約ページへ移動  */
      toReservation(course){

        if(course){

          this.$router.push({
            path: "/reservation/",
            query: {
              course: course,
              shop: this.shop.no
            }
          })


        }else {

          if (this.$refs.form.validate()) {
            this.$router.push({
              path: "/reservation/",
              query: {
                course: this.course,
                shop: this.shop.no
              }
            })
          }

        }



      },

			/** ____________________________________________________________________ API fcmsから情報を取得  */
			async setFcms(info) {
				this.fcms = info.data;

				// 取得エラー
				if(this.fcms.result != 'success') {
					this.result = 'error'
				}

				// 注意事項
				if(this.fcms[0].contents == 5 && this.fcms[0].contents_bloc == 1) {
					this.fcms.note_plan 	= this.fcms[0].contents_parts1.replace(/\n/g,'<br/>'); // プラン注意事項
					this.fcms.note_cancel 	= this.fcms[0].contents_parts2.replace(/\n/g,'<br/>');	// キャンセル料
				}


			},
			/** ____________________________________________________________________ API 商品管理から取得  */
			async productsInfo(info) {
				this.products = info.data;
			},
			/** ____________________________________________________________________ API 店舗情報から取得  */
			async shopInfo(info) {
				this.shop = info.data[0];
			}
		},

	}
</script>
<style scoped>
	@import "~@/styles/style.css";

	.v-text-field > .v-input__control > .v-input__slot::before{
		border-color: rgba(0, 0, 0, 0);
	}
	.btn-reserve button{
		width:100%;
		padding:32px;
	}
	.border-none {
		border:none!Important;
	}
	.card-pa0 {
		padding:0!important;
	}
	.icon-map i {
		color: #e60044;
		font-size:24px;
	}
	.v-text-field--filled {
		border-radius:0;
	}
	.select-menu {
		border-radius:0!important;
		height: 56px;
		overflow: hidden;
	}
	.btn-reserve button,
	.btn-reserve a
	{
		height: auto!important;
		padding-top: 24px!important;
		padding-bottom: 24px!Important;
		font-weight:bold;
	}
	.btn-reserve.btn-house button {
		padding-top: 8px!important;
		padding-bottom: 8px!Important;
	}
	.btn-register{
		text-align:center;
	}
	.theme--dark.v-sheet .text-dinw,
	.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
		color: rgba(0, 0, 0, 0.87);
	}
	.btn-reserve .mdi-truck {
		font-size:40px;
		margin-right:12px;
	}
</style>
